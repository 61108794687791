<template>
  <SharedLink
    url="/"
    class="group fixed top-8 md:top-20 left-8 md:left-20 flex items-center w-fit transition-transform z-[501]"
    style="mix-blend-mode: color-burn"
  >
    <div>
      <IconsIconLogo
        class="h-48 w-auto"
        style="color: rgba(20, 25, 11, 0.88)"
      />
    </div>
  </SharedLink>
</template>

<script lang="ts" setup></script>

<style></style>
