<template>
  <div
    class="relative flex justify-center w-full bg-dark text-beige px-20 sm:px-40 xl:px-0"
  >
    <div class="absolute bottom-[100%] w-full h-[200px] overflow-x-hidden z-10">
      <div class="absolute bottom-0 flex items-end w-full h-[200px]">
        <div
          class="hidden sm:block grow h-[200px] bg-dark rounded-tr-2xl"
        ></div>
        <div class="shrink-0 w-full max-w-[55rem] h-80 bg-dark">
          <div class="w-full h-80 rounded-b-2xl bg-beige"></div>
        </div>
        <div
          class="hidden sm:block grow h-[200px] bg-dark rounded-tl-2xl"
        ></div>
      </div>
    </div>

    <div
      class="flex flex-col items-center w-full max-w-[40rem] md:max-w-[calc(90vw)] xl:max-w-[1480px] pt-80 sm:pt-128"
    >
      <div
        class="flex flex-col lg:grid lg:grid-flow-col items-start justify-between gap-80 sm:gap-48 w-full pb-128 text-14"
      >
        <div class="flex flex-col justify-between gap-24 h-full">
          <SharedLink url="/">
            <IconsIconLogo class="h-40 w-auto text-beige" />
          </SharedLink>

          All rights reserved {{ getYear(new Date()) }} © hyperfocused
        </div>

        <div class="grid grid-cols-2 gap-x-48 gap-y-64 sm:flex sm:gap-80">
          <div>
            <div class="mb-24">{{ $t("footer.contact") }}</div>

            <SharedLink url="/contact" class="w-fit mb-20">
              <UiButton size="tiny" variant="secondary">{{
                $t("footer.cta")
              }}</UiButton>
            </SharedLink>

            <div class="mb-8 max-w-256">
              {{ $t("footer.agency") }}
            </div>

            <div class="flex flex-col gap-8">
              <SharedLink
                v-for="(link, i) in contact"
                :key="i"
                :url="link.target"
                class="w-fit hover:underline whitespace-nowrap"
              >
                {{ link.text }}
              </SharedLink>
            </div>
          </div>

          <div>
            <div class="mb-24">{{ $t("footer.pages.title") }}</div>

            <div class="flex flex-col gap-8">
              <SharedLink
                v-for="(link, i) in pages"
                :key="i"
                :url="link.target"
                class="w-fit capitalize hover:underline whitespace-nowrap"
              >
                {{ link.text }}
              </SharedLink>
            </div>
          </div>

          <div>
            <div class="mb-24">{{ $t("footer.socials") }}</div>

            <div class="flex flex-col gap-8">
              <SharedLink
                v-for="(link, i) in socials"
                :key="i"
                :url="link.target"
                class="w-fit capitalize hover:underline whitespace-nowrap"
              >
                {{ link.text }}
              </SharedLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { getYear } from "date-fns";

const { t } = useNuxtApp().$i18n;
const pages = [
  {
    text: t("footer.pages.home"),
    target: "/",
  },
  {
    text: t("footer.pages.200"),
    target: "/referrals",
  },
  {
    text: t("footer.pages.terms"),
    target: "/terms",
  },
  {
    text: t("footer.pages.privacy"),
    target: "/privacy-policy",
  },
  {
    text: t("footer.pages.imprint"),
    target: "/imprint",
  },
];

const contact = [
  {
    text: "hey@hyperfocused.agency",
    target: "mailto:hey@hyperfocused.agency",
  },
  {
    text: "+49 152 05997236",
    target: "tel:+4915205997236",
  },
];

const socials = [
  {
    text: "LinkedIn",
    target: "https://www.linkedin.com/company/101812755",
  },
  {
    text: "Instagram",
    target: "https://www.instagram.com/hyperfocused_agency/",
  },
];
</script>
